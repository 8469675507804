//
//
//
//
//
//
//

import moment from "moment";
export default {
  name: "commonTips",
  data(){
    return {
      cookieVersion: this.$cookies.get("cookieVersionNew"),
      cookieNotice: this.$cookies.get("cookieNoticeNew"),
      showLayer: false,
      noticeData:{
        title: '',
        time:'',
        content: '',
        type: 1 // 1 版本通知 2 公告数据通知
      }
    }
  },
  mounted() {
    this.getVersion();
    this.$cookies.set("cookieVersion", null); // 3600 表示1个小时
    this.$cookies.set("cookieNotice", null); // 3600 表示1个小时
  },
  methods: {
    // 获取版本内容通知
    getVersion(){
      this.$axios.get('/api/Init/getVersionInform').then(res => {
        let backData = res.data.data;
        this.noticeData.title='功能更新';
        this.noticeData.time = moment(backData.issue_time * 1000).format("yyyy年MM月DD日");
        this.noticeData.content = backData.content;
        this.noticeData.type=1;
        if(!this.cookieVersion || this.cookieVersion.time !== this.noticeData.time){
          this.showLayer= true;
        } else {
          this.getNotice()
        }
      })
    },
    // 获取公告数据通知
    getNotice(){
      this.$axios.get('/api/Init/getNoticeInform').then(res => {
        let backData = res.data.data;
        this.noticeData.title = `公告更新${backData.number}期`;
        this.noticeData.time = backData.date
        this.noticeData.content = backData.list;
        this.noticeData.type = 2;
        if(!this.cookieNotice || this.cookieNotice.time !== this.noticeData.time){
          this.showLayer= true;
        }
      })
    },
    closeNotice(type){
      if(type === 1) this.getNotice()
      this.showLayer= false;
    }
  }
}
